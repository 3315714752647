<template>
  <div class="popup__bg" v-if="show">
    <div class="popup">
      <div class="popup__close" @click="$emit('update:show', false)">
        <img class="" src="@/assets/close.svg" alt="" />
      </div>
      <div class="popup__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.popup {
  width: 100vw;
  display: block;
  margin: auto;
  position: relative;
  @media (min-width: 768px) {
    width: 45vw;
  }
  &__bg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: rgba($color: #fff, $alpha: 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    img {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: rgba($color: #fff, $alpha: 1);
    }
  }
  &__content {
  }
}

.popup::v-deep img {
  width: 100%;
}
</style>
