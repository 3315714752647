<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="layout layout__bg">
    <img class="leaf leaf--left" src="@/assets/leaf-left2.png" alt="" />
    <Counter />
    <div class="layout__container">
      <Page />
    </div>
    <img class="leaf leaf--right" src="@/assets/leaf-right2.png" alt="" />
  </div>
</template>

<script>
import Counter from "./components/Counter.vue";
import Page from "./components/Page.vue";

export default {
  name: "App",
  components: {
    Counter,
    Page,
  },
};
</script>
<style lang="scss">
.layout {
  display: flex;
  &__bg {
    background: url(./assets/bg.png) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80vh;
    overflow-y: auto;
    z-index: 9;
  }
}

.leaf {
  width: 40vw;
  position: absolute;
  &--left {
    left: 0;
    bottom: 0;
    width: 24vw;
  }
  &--right {
    right: 0;
    top: 0;
    opacity: 0.7;
    @media (min-width: 768px) {
      opacity: 1;
    }
  }
}
</style>
