<template>
  <div class="text">
    <div class="text__content">
      <slot></slot>
    </div>
    <img v-if="icon" src="@/assets/flower.svg" alt="" class="text__icon" />
  </div>
</template>

<script>
export default {
  props: ["icon"],
};
</script>

<style lang="scss" scoped>
.text {
  display: flex;
  flex-direction: column;
  &__content {
    text-transform: uppercase;
    text-align: center;
    line-height: 1.75em;
    font-size: 2em;
    @media (min-width: 768px) {
      line-height: 35px;
      font-size: 1.25em;
    }
  }
  &__icon {
    width: 200px;
    margin: auto;
    margin-top: 4rem;
    @media (min-width: 768px) {
      width: 266px;
    }
  }
}
.text::v-deep .text__gold {
  color: $gold;
}
.text::v-deep .text__img {
  width: 40vw;
  @media (min-width: 768px) {
    width: 12vw;
  }
}
.text::v-deep .text__decoration {
  font-family: "Parisienne";
  line-height: normal;
  font-size: 3em;
  color: $darkgreen;
  text-transform: none;
  font-weight: $regular;
}
</style>
