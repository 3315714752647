<template>
  <div class="pages">
    <div class="pages__section">
      <!-- <Names /> -->
      <Text :icon="false">
        <div class="names">
          <h1 class="names__title">Pobieramy się!</h1>
          <h2 class="names__name">Ilona i Michał</h2>
        </div>
      </Text>
    </div>
    <div class="pages__section">
      <Text :icon="true">
        <p>
          <span class="text__gold">Ilona Walczak i Michał Wiśniewski</span
          ><br />
          Ogarnięci wzniosłymi ideałami życia małżeńskiego<br />
          oraz wolą skrócenia niebezpiecznie przedłużającego się<br />
          narzeczeństwa postanowili<br />
          <span class="text__gold">udzielić sobie Sakramentu Małżeństwa.</span>
        </p>
      </Text>
    </div>
    <div class="pages__section">
      <Text :icon="true">
        <p>
          W imieniu Rodziców i własnym <br />
          serdecznie zapraszamy<br />
          na uroczystość zaślubin, która odbędzie się <br />
          <strong
            >30 kwietnia 2022 roku<br />
            o godzinie 14:00 w Kościele pw. św. Rocha<br />
            przy ul. św Rocha 10 w Poznaniu.</strong
          >
        </p>
      </Text>
    </div>
    <div class="pages__section">
      <Text :icon="true">
        <p>
          Po uroczystości zaślubin odbędzie się przyjęcie weselne <br />
          w
          <strong
            >“Hotelu Wityng”<br />
            w Mikorzynie 1A koło Ślesina.</strong
          >
        </p>
      </Text>
    </div>
    <div class="pages__section">
      <Icons />
    </div>
    <div class="pages__section">
      <Text :icon="true">
        <img class="text__img" src="@/assets/rebus.png" alt="" />
        <p>
          Będzie nam bardzo miło,<br />
          jeżeli zamiast kwiatów<br />
          obdarujecie nas kuponem lotto bądź zdrapką.
        </p>
      </Text>
    </div>
    <div class="pages__section pages__section--last">
      <Text :icon="false">
        <p class="text__decoration">
          Nie możemy się Was doczekać<br />
          :)
        </p>
      </Text>
    </div>
  </div>
</template>

<script>
// import Names from "../components/pages/Names.vue";
import Text from "../components/Text.vue";
import Icons from "../components/Icons.vue";
export default {
  components: {
    // Names,
    Text,
    Icons,
  },
};
</script>

<style lang="scss" scoped>
.pages {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 9;
  &::-webkit-scrollbar {
    display: none;
  }
  &__section {
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &--last {
      // height: 50%;
    }
  }
}
.names {
  &__title {
    font-family: "Parisienne";
    font-size: 4em;
    color: $darkgreen;
    font-weight: $regular;
    text-transform: none;
    animation: enterNames 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
    @media (min-width: 768px) {
      font-size: 6.813em;
    }
  }
  &__name {
    display: inline-block;
    margin-top: 4rem;
    text-transform: uppercase;
    font-weight: $regular;
    text-align: center;
    font-size: 2.25em;
    animation: enterNames 2s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    opacity: 0;
  }
}

@keyframes enterNames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
