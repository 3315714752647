<template>
  <div class="icons">
    <h2 class="icons__title">Przydatne informacje</h2>
    <div class="icons__items">
      <a
        href="https://www.google.com/maps/dir//Parafia+rzymskokatolicka+%C5%9Bw.+Rocha,+%C5%9Awi%C4%99tego+Rocha+10,+61-142+Pozna%C5%84,+Polska/@52.4001676,16.9140412,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47045b13e178a199:0xd70a0d7f720a2a5e!2m2!1d16.9490378!2d52.4000913!3e0"
        target="_blank"
        class="icons__item"
      >
        <img class="" src="@/assets/church.svg" alt="" />
        <p>Trasa do kościoła</p>
      </a>
      <a
        href="https://www.google.com/maps/dir//Wityng+Sp.+z+o.o.+O%C5%9Brodek+szkoleniowo+-+wypoczynkowy,+Mikorzyn+1A,+62-561+Mikorzyn,+Polska/@52.3451935,18.2660424,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x471b4a3136aa9269:0xbc158f7607f22a82!2m2!1d18.3010617!2d52.3451462!3e0"
        target="_blank"
        class="icons__item"
      >
        <img class="" src="@/assets/music.svg" alt="" />
        <p>
          Trasa na salę <br />
          Weselną
        </p>
      </a>
      <div class="icons__item" @click="showPopup = true">
        <img class="" src="@/assets/park.svg" alt="" />
        <p>
          Mapka z dostępnymi<br />
          parkingami <br />
          przy kościele
        </p>
      </div>
      <a
        href="https://youtu.be/VVEw6HvWwf8"
        target="_blank"
        class="icons__item"
      >
        <img class="" src="@/assets/photo.svg" alt="" />
        <p>
          Transmisja <br />
          Ze ślubu
        </p>
      </a>
    </div>
  </div>
  <Popup v-model:show="showPopup">
    <img class="" src="@/assets/map.jpg" alt="" />
  </Popup>
</template>

<script>
import { ref } from "@vue/reactivity";
import Popup from "../components/Popup.vue";
export default {
  components: {
    Popup,
  },
  setup() {
    const showPopup = ref(false);
    return { showPopup };
  },
};
</script>

<style lang="scss" scoped>
.icons {
  width: 75%;
  &__title {
    margin-bottom: 4rem;
    text-align: center;
    font-family: "Parisienne";
    line-height: normal;
    font-size: 5em;
    color: $darkgreen;
    text-transform: none;
    font-weight: $regular;
    @media (min-width: 768px) {
      font-size: 3em;
    }
  }
  &__items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__item {
    text-align: center;
    cursor: pointer;
    position: relative;
    width: calc(100% / 2);
    margin-bottom: 2rem;
    @media (min-width: 768px) {
      width: calc(100% / 4);
      margin-bottom: 0;
    }
    img {
      height: 80px;
      width: auto;
      @media (min-width: 768px) {
        height: 110px;
      }
    }
    p {
      width: 100%;
      text-transform: uppercase;
      color: $darkgreen;
      margin-top: 1rem;
      transition: all 0.3s;
      position: absolute;
      opacity: 0;
      @media (max-width: 768px) {
        opacity: 1;
        font-size: 1.6em;
        position: unset;
      }
    }
    &:hover {
      p {
        opacity: 1;
      }
    }
  }
}
</style>
