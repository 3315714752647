<template>
  <div class="counter">
    <div class="counter__content" v-if="result > 0">
      <h2 class="counter__text">Pozostało</h2>
      <div class="counter__number">{{ result }}</div>
      <h2 class="counter__text">dni</h2>
    </div>
    <div class="counter__content" v-else>
      <h2 class="counter__text">To</h2>
      <div class="counter__number">już</div>
      <h2 class="counter__text">dziś!</h2>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    const counter = ref(25);
    const show = ref(false);

    const end = new Date("Apr 30, 2022 14:00:00").getTime();
    const seconds = 1000;
    const minutes = seconds * 60;
    const hours = minutes * 60;
    const days = hours * 24;

    const result = ref(0);

    const x = setInterval(() => {
      let now = new Date().getTime();
      const difference = end - now;

      if (difference < 0) {
        clearInterval(x);
        console.log("stop");
        return;
      }

      result.value = Math.floor(difference / days);
    }, seconds);

    onMounted(() => {
      show.value = true;
    });

    return { counter, result, show };
  },
};
</script>

<style lang="scss" scoped>
.counter {
  position: fixed;
  top: 0;
  top: -200px;
  width: 100%;
  padding-top: 0rem;
  animation: enter 2s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  opacity: 0;
  z-index: 9;
  @media (min-width: 768px) {
    padding-top: 3rem;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text {
    display: inline-block;
    color: $green;
    text-transform: uppercase;
    font-weight: $regular;
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
    font-size: 4em;
    @media (min-width: 768px) {
      font-size: 2em;
    }
  }
  &__number {
    font-size: 5.625em;
    text-transform: uppercase;
    color: $green;
    margin: 0 10px;
  }
}

@keyframes enter {
  from {
    top: -200px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
</style>
